@import '@/style/theme.less';
@import '@/style/extends.less';

.fle-layout-sider {
  left: 0;
  display: flex;
  height: 100%;
  background-color: transparent !important;

  &-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 63px;
    padding: 8px 0;
    border-top: 1px solid #eee;
    background-color: #fff;

    .anticon {
      font-size: 24px;
      color: #09244b;
      margin: 12px 0;
      cursor: pointer;

      &:hover {
        color: @primary-color;
      }
    }
  }

  &-one-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 180px;
    height: 100%;
    padding-top: 54px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 2;

    &.fle-scroll:hover {
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        display: block;
      }
    }

    &-list {
      padding: 16px;
    }

    &-cell {
      position: relative;
      display: flex;
      align-items: center;
      color: #333;
      line-height: 18px;
      padding: 7px 16px 7px 42px;
      cursor: pointer;

      .anticon {
        position: absolute;
        left: 16px;
        top: 8px;
        font-size: 16px;
        margin-right: 10px;
      }

      &.active {
        color: @primary-color;
        background-color: #ddebff;
      }

      &:hover {
        color: @primary-color;
      }
    }

    &-group {
      &-name {
        color: #777;
        line-height: 32px;
        padding: 0 16px;
      }
    }

    &-btn {
      text-align: center;
      cursor: pointer;

      img {
        height: 36px;
        margin-bottom: 16px;
      }
    }
  }

  &-wrap {
    z-index: 13;
  }

  &-first-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 64px;
    height: 100%;
    padding-top: 64px;
    padding-bottom: 236px;
    overflow-y: auto;
    background-color: #fff;
    border-right: 1px solid #eee;

    &.fle-scroll:hover {
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        display: block;
      }
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 64px;
      padding: 12px 0;
      cursor: pointer;
      color: #333;

      .anticon {
        font-size: 20px;
        color: #09244b;

        &.selected-menu-icon {
          display: none;
        }
      }

      &.active,
      &:hover {
        transition: all 0.3s;
        & > span {
          color: @primary-color;
        }

        .menu-icon {
          display: none;
        }

        .selected-menu-icon {
          display: block;
        }
      }
    }
  }

  &-second-menu {
    position: fixed;
    top: 0;
    left: 64px;
    height: 100%;
    width: 116px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 92px 8px 16px;
    background-color: #fafbfd;
    border-right: 1px solid #f3f5f9;
    z-index: 3;

    &.fle-scroll:hover {
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        display: block;
      }
    }

    .second-menu-group {
      padding-bottom: 16px;
    }

    .second-menu-line {
      width: 84px;
      height: 1px;
      background: #eee;
    }

    .second-menu-icon {
      font-size: 14px;
    }

    .second-menu-group-title {
      font-size: 12px;
      color: #aaa;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      padding-top: 16px;
      white-space: nowrap;
    }

    .second-menu-icon {
      margin-right: 2px;
    }

    .second-menu-item {
      display: inline-block;
      color: #333;
      line-height: 20px;
      padding-top: 16px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: @primary-color;
      }

      &.active {
        font-weight: 500;
        color: @primary-color;
      }
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;