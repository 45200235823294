@import '@/style/theme.less';

@pkg: fle-layout-header-menu;

.@{pkg} {
  display: flex;
  align-items: center;
  width: 100%;

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 56px;
    margin-right: 40px;
    padding-right: 4px;
    font-weight: 500;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    .anticon {
      color: #333;
      font-size: 24px;
      margin-right: 8px;
    }

    img {
      width: 20px;
      margin-right: 8px;
    }

    &-name {
      font-weight: 500;
      color: #333;
      white-space: nowrap;
    }

    &-tag {
      position: absolute;
      right: -30px;
      top: 10px;
      padding: 2px 4px;
      border-radius: 2px;
      background-color: orange;
      color: #fff;
      line-height: 1;
      font-size: 12px;
      transform: scale(0.8);
    }

    &-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }

    &:hover &-name,
    &:hover .anticon,
    &.active &-name,
    &.active .anticon {
      color: @primary-color;
    }

    &.active &-line {
      background: linear-gradient(270deg, #65d8f1 0%, #2c6bff 100%);
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;