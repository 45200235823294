.fx__layout-page-tabs {
  position: fixed;
  top: 56px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(97, 97, 97, 0.05);
  white-space: nowrap;
  transition: all 0.2s ease 0s;
  z-index: 11;

  .page-tabs-nav-wrapper {
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .page-tabs-nav-wrapper::-webkit-scrollbar {
    display: none;
  }

  .page-tabs-nav {
    display: flex;
    width: max-content;
    transition: all 0.2s linear;
  }

  .page-tab {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 6px;
    height: 36px;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      background-color: transparent;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: '';
      width: 1px;
      height: 16px;
      background-color: #ddd;
      transform: translateY(-50%);
    }

    .page-tab-link {
      display: flex;
      align-items: center;
      padding: 7px 10px 5px;
      gap: 4px;
      font-size: 14px;
      color: #000;
      line-height: 22px;
      cursor: pointer;

      .anticon {
        font-size: 18px;
      }
    }

    .page-tab-close {
      margin-right: 10px;
      font-size: 12px;
      color: #aaa;
      transform: translate(0, 1px);
      transition: all 0.1s linear;
      cursor: pointer;
      border-radius: 50%;
      background-color: transparent;

      &:hover {
        color: #fff;
        background-color: #aaa;
      }
    }

    &.active::before {
      background-color: @primary-color;
    }

    &.active .page-tab-link,
    &:hover .page-tab-link {
      color: @primary-color;
    }
  }
  .page-tabs-actions {
    display: flex;
    user-select: none;

    .page-tabs-action {
      position: relative;
      padding: 4px 8px;
      cursor: pointer;
      transition: all 0.2s linear;
      color: #09244b;
      font-size: 16px;
      border-bottom: 2px solid transparent;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        width: 1px;
        height: 16px;
        background-color: #ddd;
        transform: translateY(-50%);
      }

      &:hover {
        color: @primary-color;
      }
    }
  }
}

.page-tabs-action-more .fle-popover-content .fle-popover-inner {
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 0 16px 0 #c9d6ed, inset 0 1px 1px 1px rgba(255, 255, 255, 0.6);
  transform: translate(0, -1px);
  user-select: none;

  .fle-popover-inner-content {
    padding: 0;
  }

  .action-more {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 16px;
  }

  .action {
    display: flex;
    gap: 8px;
    color: #333;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s linear;
    align-items: center;
    line-height: 16px;

    &:hover {
      color: @primary-color;
    }
  }

  .anticon {
    font-size: 16px;
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;