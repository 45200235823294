@import '@/style/theme.less';

.peliten__layout {
  .fle-pro-global-header {
    margin: 0;
  }
  &:first-child::after {
    height: 0.5rem;
    width: 1px;
    background-color: #e5e5e5;
    content: '';
  }

  .fle-pro-layout-container {
    flex: 1;
  }

  .fle-layout-header {
    &.fle-pro-layout-header-fixed-header {
      .fle-skeleton.fle-skeleton-element {
        display: flex;
        justify-content: center;
      }

      .fle-pro-top-nav-header-wide {
        min-width: 900px;
        max-width: none;
      }

      .fle-pro-top-nav-header-menu {
        padding: 0;
        line-height: 50px;
      }
    }

    .fle-layout * {
      min-width: none;
    }

    .fle-pro-global-header-header-actions-item > * {
      padding: 0 16px;
      border-radius: 0;
    }

    .fle-pro-global-header-header-actions-item {
      color: #333;
      font-size: 14px;
      cursor: pointer;

      a {
        display: flex;
        padding: 0 9px;
        color: rgb(51, 51, 51);

        &:hover .anticon {
          color: @primary-color;
        }
      }

      .anticon {
        font-size: 18px;
      }

      .anticon-down {
        font-size: 12px;
        margin-left: 6px;
      }
    }

    .fle-pro-global-header-header-actions-item > *:hover {
      background: none;
      color: @primary-color;
    }
    .fle-header-action-item-div {
      padding: 0 6px;
      display: flex;
      align-items: center;
    }
    .fle-header-action-item-icon:hover {
      color: @primary-color;
    }
    .fle-pro-global-header-header-actions-avatar {
      padding: 0;

      & > div {
        color: #333;
        height: 56px;
        padding: 0 16px;
      }
    }
  }

  .fle-menu .fle-menu-item a {
    transition: none !important;
  }

  .fle-pro-layout .fle-pro-sider .fle-layout-sider-children {
    padding: 0 4px;
  }

  .fle-pro-sider-collapsed-button {
    top: 44px !important;
  }

  .fle-layout-content {
    overflow-y: auto;
  }

  .fx__layout-content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    min-width: 1240px;
    // max-width: 1740px;
  }

  .fle-pro-grid-content-wide {
    max-width: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .fle-console-btn {
    display: none !important;
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;