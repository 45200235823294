@import '@/style/theme.less';

.fle-pro-global-footer-copyright {
  font-size: 12px !important;
  color: rgba(119, 119, 119, 1) !important;
}

.fle-pro-global-footer {
  margin-block-start: 36px;

  .fle-pro-global-footer-list {
    .fle-pro-global-footer-list-link {
      color: rgba(119, 119, 119, 1);
      font-size: 12px;

      &:hover {
        color: #000;
      }
    }
  }
}

.message-drawer-root {
  top: 56px;
  left: 64px;
  z-index: 12;

  .fle-drawer-header {
    padding: 16px !important;
    .fle-drawer-header-title {
      flex-direction: row-reverse;
      .fle-drawer-close {
        padding: 0;
        margin: 0;
      }
    }
  }
  .fle-drawer-body {
    padding: 0 16px !important;
    position: relative;
    background-color: #f2f5f9;

    .tab-class {
      .fle-tabs-nav::before {
        border: #000;
      }
      .fle-tabs-content-holder {
        padding-top: 40px;
        // height: calc(100vh - 150px);
        // height: 400px;
      }
      .fle-tabs-tab {
        padding: 16px 0 4px;
      }
      .fle-tabs-tab-active {
        .fle-tabs-tab-btn {
          .fle-badge {
            color: #2c6bff;
          }
        }
      }
    }
    .has-read-box {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #777;
      cursor: pointer;
    }
    .message-tab-box {
      position: absolute;
      width: 100%;
      height: 40px;
      display: flex;
      top: 56px;
      left: 0;

      .message-tab {
        font-size: 12px;
        color: #aaa;
        padding: 4px 16px;
        margin-left: 16px;
        line-height: 14px;
        cursor: pointer;
      }
      .message-tab-active {
        background: #fff;
        border-radius: 2px;
        height: 24px;
        color: #2c6bff;
      }
    }
  }
}

.message-box-root {
  width: 100%;
  min-height: 127px;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 8px;
  z-index: 1;
  .message-app-name {
    font-size: 12px;
    color: #aaa;
    margin-bottom: 4px;
  }
  .message-title-box {
    display: flex;
    justify-content: space-between;
    line-height: 22px;
    margin-bottom: 4px;
    .message-title {
      font-size: 16px;
      color: #333;
      .red-dom {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #f42022;
        border-radius: 50%;
        margin: 0 0 7px 2px;
      }
    }
    .message-time {
      font-size: 12px;
      color: #aaa;
    }
  }
  .message-content {
    margin-bottom: 8px;
    font-size: 14px;
    color: #aaa;
  }
  .message-see-detail {
    font-size: 14px;
    color: #2c6bff;
    // float: right;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
.content-scroll {
  height: 600px;
}
.content-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.message-no-data {
  width: 180px;
  height: 180px;
  margin: 150px auto;
  .no-data-img {
    width: 100%;
    height: 100%;
  }
}
.fle-empty-image {
  display: none;
}
.fle-empty-description {
  display: none;
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;