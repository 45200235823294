.create-company-modal {
  .text-primary-color {
    color: @primary-color;
  }

  .pointer {
    cursor: pointer;
  }

  .protocols-text {
    position: absolute;
    top: 0;
    left: 20px;
    line-height: 34px;
    white-space: nowrap;
  }
}

.finish-modal {
  text-align: center;
  font-size: 22px !important;
  font-weight: 600;
  color: #333;
  margin-bottom: 56px !important;

  img {
    width: 136px;
    height: 112px;
    margin-top: 32px;
    margin-bottom: 24px;
    display: inline-block;
  }
}

.fx__btn {
  font-size: 18px !important;
  height: 54px !important;
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;