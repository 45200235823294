@import './theme.less';
@import './global.less';

.ant-layout.ant-pro-layout {
  background-color: transparent !important;
}

.flex {
  display: flex;

  &-flexwrap {
    flex-wrap: wrap;
  }

  // justify-content
  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-right {
    justify-content: flex-end;
  }

  // align-items
  &-align-center {
    align-items: center;
  }

  &-align-initial {
    align-items: initial;
  }

  &-align-end {
    align-items: flex-end;
  }

  &-align-start {
    align-items: flex-start;
  }

  // align-content
  &-content-around {
    align-content: space-around;
  }

  &-vertical {
    flex-direction: column;
    height: 100%;

    .fx-flexbox-item {
      width: 100%;
    }
  }

  &-item {
    flex: 1;

    &-start {
      align-self: flex-start;
      justify-self: flex-end;
    }

    &-center {
      align-self: center;
    }

    &-end {
      align-self: flex-end;
    }
  }
}

.fle-scroll {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
    border-radius: 3px;
    background-color: #ddd;
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;