.fle-app {
  .fle-pro-layout {
    .fle-pro-layout-content {
      padding: 16px;
      background-color: transparent;
    }
    .fle-pro-page-container {
      background-color: #fff;
      border-radius: 2px;

      .fle-tabs .fle-tabs-tab {
        padding-top: 0;
      }
    }
  }

  .fle-pagination-options {
    .fle-pagination-options-size-changer {
      .fle-select-selector {
        background-color: #f1f1f1;
        border-color: #f1f1f1;
      }
    }
  }

  .fle-btn.fle-btn-default {
    border-color: transparent;
    background-color: #33333314;
    box-shadow: none;
  }

  .fle-radio-button-wrapper {
    background-color: #33333314;
  }

  // 注意table统一border为false
  .fle-table > .fle-table-container {
    border-top: 1px solid rgb(238, 238, 238);
    border-left: 1px solid rgb(238, 238, 238);
    border-right: 1px solid rgb(238, 238, 238);
  }

  .fle-header-action-item {
    display: flex;
    padding-inline: 8px;
    align-items: center;
    height: 44px;

    .anticon {
      font-size: 18px;
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;