.price-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.price-button {
  position: fixed;
  width: 66px;
  height: 85px;
  bottom: 140px;
  right: 4px;
  transform: translate(0, 0);
  cursor: pointer;
  z-index: 999;

  .price-button-header {
    position: relative;
    width: 58px;
    height: 66px;
    left: -10px;
    bottom: -22px;
    -webkit-user-drag: none;
    overflow-clip-margin: content-box;
    overflow: initial !important;
    cursor: grab;
  }

  .price-button-items-wrapper {
    position: relative;
    background: #2c6bff;
    box-shadow: 0 0 8px 0 #2c6bff;
    border-radius: 8px;
    z-index: 10;

    .price-button-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 40px;
      font-size: 24px;
      border-bottom: 1px solid #1f54d1;
      margin: 0 auto;

      &:last-child {
        border: none;
      }
    }
  }
}

.fle-modal-content2 {
  .fle-modal-content {
    border-radius: 24px;
  }
  .fle-modal-header {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .price-modal-close-img {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .price-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price-modal-btn-left {
      font-weight: 400;
      font-size: 12px;
      color: #a6a6a6;
      margin-left: 36px;
    }
    .price-modal-btn-right {
      width: 177px;
      height: 51px;
      cursor: pointer;
    }
  }
  .price-modal-container {
    margin: -24px 0;
    .no-data-wrap {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      padding: 48px 0;
      .no-data-img {
        width: 383px;
        height: 224px;
        margin-bottom: 17px;
      }
    }
    .price-modal-header {
      position: relative;
      height: 73px;
      margin-bottom: 16px;
      .price-modal-header-img {
        width: 100%;
        height: 100%;
      }
      .goods-num {
        position: absolute;
        top: 20px;
        left: 250px;
        font-weight: 800;
        font-size: 26px;
        color: #fff;
        width: 52px;
        text-align: center;
      }
      .goods-per {
        position: absolute;
        top: 20px;
        left: 525px;
        font-weight: 800;
        font-size: 26px;
        color: #fff;
      }
    }
    .price-modal-main {
      height: 408px;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      .price-goods-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(135deg, #fff0da 0%, #ffefd4 100%);
        border-radius: 16px;
        // min-height: 117px;
        min-height: 141px;
        padding: 3px 17px;
        margin-bottom: 8px;
        .goods-item-left {
          display: flex;
          align-items: center;
        }
        .price-goods-img {
          width: 107px;
          height: 107px;
          border-radius: 16px;
          margin: 0 11px 0 18px;
        }
        .price-goods-title {
          font-weight: 500;
          font-size: 16px;
          color: #333;
          line-height: 22px;
          margin-bottom: 4px;
          max-width: 450px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        // .price-goods-type {
        //     height: 28px;
        //     display: inline-flex;
        //     align-items: center;
        //     text-align: center;
        //     padding: 8px 4px;
        //     background: #FFFFFF;
        //     border-radius: 6px;
        //     border: 1px solid #DC700D;
        //     font-weight: 400;
        //     font-size: 14px;
        //     color: #DC700D;
        // }
        .self-price-img {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
        .price-goods-per {
          position: relative;
          width: 110px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
          .price-goods-per-price {
            position: absolute;
            left: 0;
            bottom: 16px;
            font-weight: 800;
            font-size: 26px;
            color: #fff;
            width: 110px;
            text-align: center;
          }
          .price-goods-per-title {
            position: absolute;
            left: 42px;
            top: 34px;
            font-weight: 800;
            font-size: 14px;
            color: #fff;
          }
        }
        .price-goods-price-new {
          height: 52px;
          background: linear-gradient(270deg, #fff1da 0%, #ffda9e 100%);
          border-radius: 12px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          padding: 6px 8px;
          .price-goods-price-new-left {
            display: flex;
            align-items: center;
            .text_99 {
              height: 16px;
              overflow-wrap: break-word;
              color: rgba(237, 0, 0, 1);
              font-size: 16px;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              white-space: nowrap;
              line-height: 16px;
              margin-top: 4px;
            }

            .text_100 {
              height: 16px;
              overflow-wrap: break-word;
              color: rgba(237, 0, 0, 1);
              font-size: 24px;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              white-space: nowrap;
              line-height: 16px;
            }

            .text_101 {
              height: 16px;
              overflow-wrap: break-word;
              color: rgba(237, 0, 0, 1);
              font-size: 16px;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              white-space: nowrap;
              line-height: 16px;
              margin-top: 4px;
            }
          }
          .price-goods-price-new-img img {
            width: 24px;
            height: 40px;
            margin: 0 8px;
          }
          .text_102 {
            width: 59px;
            height: 16px;
            overflow-wrap: break-word;
            color: rgba(237, 0, 0, 1);
            font-size: 16px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 16px;
            margin: 18px 158px 0 8px;
          }
        }
        .price-goods-price {
          display: flex;
          align-items: center;
          .goods-price-left {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #969696;
            line-height: 20px;
            // margin-right: 14px;
            // min-width: 130px;
          }
          .goods-price-right {
            display: flex;
            align-items: center;
            color: #ed0100;
          }
        }
      }
    }
    .fle-checkbox-inner {
      border-color: #e3e3e3 !important; /* 这里设置你想要的颜色 */
    }
    .fle-checkbox-checked .fle-checkbox-inner {
      background-color: #f8a832 !important;
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;